import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import Ripple from 'vuetify/lib/directives/ripple';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSelect,{attrs:{"label":_vm.label,"items":_vm.items,"value":_vm.model,"item-value":"id","multiple":"","dense":"","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.isCategoryInMaster(item))?_c(VChip,{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name))]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"v-chip__close",attrs:{"right":"","size":18},on:{"click":function($event){$event.stopPropagation();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock ")])]}}],null,true)},[_c('span',[_vm._v("Fra masterbutikk")])])],1):_vm._e(),(!_vm.isCategoryInMaster(item))?_c(VChip,{attrs:{"small":"","close":""},on:{"click:close":function($event){return _vm.removeSelection(item)}}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItem,{directives:[{def: Ripple,name:"ripple",rawName:"v-ripple"}]},[_c(VListItemAction,[_c(VSimpleCheckbox,{attrs:{"color":"primary","disabled":_vm.isCategoryInMaster(item),"value":_vm.isItemSelected(item)},on:{"input":function($event){return _vm.onItemInput(item, $event)}}})],1),_c(VListItemContent,[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }